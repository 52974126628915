import { gql } from "@apollo/client";

import { PrerecordedVoiceMessage } from "Types/Recordings";

export const DEFAULT_QUERY_LOCATION = "GQLGetPrerecordedVoiceMessages";
export type PrerecordedVoiceMessagesResponse = {
    getPrerecordedVoiceMessages: PrerecordedVoiceMessage[];
};

export type PrerecordedVoiceMessagesForUserResponse = {
    getPrerecordedVoiceMessagesForUser: PrerecordedVoiceMessage[];
};

export type PreferedAgentVMDropResponse = {
    getPreselectedVMDropNoDuplicates: {
        defaultRecording: PrerecordedVoiceMessage | null;
        list: PrerecordedVoiceMessage[];
    };
};

export type QueryablePrerecordedVoiceMessagesResponse = {
    getPrerecordedVoiceMessagesByQuery: PrerecordedVoiceMessage[];
};

const gqlResponse = `
    id
    isDefault
    createdAt
    createdBy
    updatedAt
    updatedBy
    type
    friendlyName
    recordingFilename
    url
    subBrands
    phoneCallCampaigns {
        id
        campaign {
            id
            name
            state
            friendlyId
        }
    }
`;

export const getPrerecordedVoiceMessagesQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking}($type: String) {
        getPrerecordedVoiceMessages(type: $type) {
           ${gqlResponse}
        }
    }`;
};

export const getQueryablePrerecordedVoiceMessagesWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking}($queryParams: QueryParams!) {
       getPrerecordedVoiceMessagesByQuery(queryParams: $queryParams) {
           ${gqlResponse}
        }
    }`;
};

// get all of the agents voicemail recordings
export const getPrerecordedVoiceMessagesForUserQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking} {
        getPrerecordedVoiceMessagesForUser {
           ${gqlResponse}
        }
    }`;
};

// An agents voicemail, potentially scoped to a campaign
export const getPreferedAgentVMDropsQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking} ($contactPhone: String, $campaignId: String, $profileId: String) {
        getPreselectedVMDropNoDuplicates(contactPhone: $contactPhone, campaignId: $campaignId, profileId: $profileId) {
          defaultRecording {
            ${gqlResponse}
        },
          list {
            ${gqlResponse}
           }
        }
    }`;
};

// Get campaigns for a specific voicemail recording
export const getCampaignsByVMQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking} ($id: String!) {
        getCampaignsByVM(id: $id) {
          id
          name
          friendlyId
          state
        }
    }`;
};
